<template>
  <v-container fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="3">
        <!--  -->
        <v-card class="pa-4">
          <v-card-text v-if="user">
            <v-row>
              <v-col cols="12" class="pb-0">
                <strong>{{ $phraser.set('client:') }}</strong>
              </v-col>
              <v-col cols="12" class="pt-1">
                <span v-if="clients">
                  {{ clients.find(x => x.value == user.client).text }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <strong>{{ $phraser.set('updated at:') }}</strong>
              </v-col>
              <v-col cols="12" class="pt-1">
                <span>{{ formatTime(user.updated_at) }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <strong>{{ $phraser.set('created at:') }}</strong>
              </v-col>
              <v-col cols="12" class="pt-1">
                <span>{{ formatTime(user.created_at) }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="8">
        <base-material-card color="indigo">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light" color="white">
              {{ $phraser.set('edit details') }}
            </div>
          </template>
        </base-material-card>
        <v-form
          v-if="Object.keys(form).length"
          ref="form"
          @submit.prevent="submit"
        >
          <v-container class="py-0">
            <v-row justify="space-around">
              <v-col cols="12" md="4">
                <base-smart-input
                  :model.sync="form.first_name"
                  :label="$phraser.set(`first name`)"
                  min="2"
                  max="25"
                  required
                  :disabled="loading"
                />
              </v-col>

              <v-col cols="12" md="4">
                <base-smart-input
                  :model.sync="form.last_name"
                  :label="$phraser.set(`last name`)"
                  min="2"
                  max="25"
                  required
                  :disabled="loading"
                />
              </v-col>

              <v-col cols="12" md="4">
                <base-smart-input
                  :model.sync="form.email"
                  :label="$phraser.set(`email`)"
                  type="email"
                  :disabled="loading"
                />
              </v-col>

              <v-row justify="center" class="w-100">
                <v-col cols="12" md="5">
                  <base-smart-input
                    :model.sync="form.phone"
                    :label="$phraser.set(`phone`)"
                    length="10"
                    numberic
                    type="tel"
                    :disabled="loading"
                  />
                </v-col>

                <v-col cols="12" md="5">
                  <base-smart-input
                    type="password"
                    :model.sync="form.password"
                    :label="$phraser.set(`password`)"
                    :placeholder="$phraser.set(`password`)"
                    min="6"
                    max="18"
                    counter
                    :disabled="loading"
                    hint="להשאיר ריק במידה ולא רוצים לשנות"
                    dir="ltr"
                  />
                </v-col>
              </v-row>

              <v-col cols="12" md="4">
                <v-select
                  v-model="form.client"
                  :items="clients"
                  :label="$t('client')"
                  :disabled="loading"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  v-model="form.locale"
                  :items="languages"
                  :label="$t('locale')"
                  :disabled="loading"
                />
              </v-col>

              <v-col v-if="!!editSMS" cols="12" md="4">
                <v-select
                  v-model="form.notifications.settings.smsPackage"
                  :items="sms_packages"
                  :label="$phraser.set('sms package')"
                  :disabled="loading"
                />
              </v-col>

              <v-row justify="center">
                <v-col cols="12" md="4">
                  <v-radio-group
                    v-model="form.notifications.settings.emails"
                    row
                    :disabled="loading"
                  >
                    <label for="">{{ $phraser.set('emailnots:') }}</label>
                    <v-radio :label="$t('yes')" :value="1" />
                    <v-radio :label="$t('no')" :value="0" />
                  </v-radio-group>
                </v-col>

                <v-col cols="12" md="4">
                  <v-radio-group
                    v-model="form.notifications.settings.sms"
                    :disabled="loading"
                    row
                  >
                    <label for="">{{ $phraser.set('smsnots:') }}</label>
                    <v-radio :label="$t('yes')" :value="1" />
                    <v-radio :label="$t('no')" :value="0" />
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                color="success"
                class="mr-0 text-h4"
                block
                type="submit"
                :loading="loading"
              >
                {{ $phraser.set('update details') }}
              </v-btn>
            </v-col>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
    <v-dialog v-model="loadingDialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="white--text">
          <h4 class="text-center mb-4">
            {{ loadingText }}
          </h4>
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment'
export default {
  name: 'UserDetails',

  data: () => ({
    form: {},
    initiated: false,
    submitted: false,
    pwdTooltip: true,
    loading: false,
    userInterval: null,
    loadingDialog: false,
    loadingText: '',
    languages: [
      {
        text: 'עברית',
        value: 'he',
      },
      {
        text: 'English',
        value: 'en',
      },
    ],
    sms_packages: [],
  }),

  computed: {
    user () {
      return this.$store.getters['users/getBySlug'](this.$route.params.slug)
    },
    phoneToken () {
      return this.$store.getters['confirmphone/temporary']
    },
    clients () {
      const clients = this.$store.getters['clients/clientsCodesNames']
      return Object.values(clients).map(client => {
        return client
      })
    },
    editSMS () {
      return this.$store.getters['user/can']('edit_sms')
    },
  },

  watch: {
    phoneToken (token, old) {
      if (!old && token && this.submitted) {
        this.form.phone_token = token
        this.loading = true
        setTimeout(() => {
          this.$store.dispatch('users/editUser', this.form)
        }, 2000)
      }
    },
  },

  created () {
    moment.locale(this.$i18n.locale)
  },

  mounted () {
    if (this.user) {
      this.setForm().then(() => {
        this.$refs.form.resetValidation()
      })
    }
    this.sms_packages.push({
      text: this.$t('without'),
      value: 0,
    })
    this.sms_packages.push({
      text: this.$t('unlimited'),
      value: 1,
    })
  },

  methods: {
    async setForm () {
      this.form = { ...this.user }
      this.form.slug = this.user.slug
      this.form.user = this.user.email
      this.form.phone = this.user.phone ?? ''
    },
    async submit () {
      // console.log(this.form)
      // if (this.form) return false
      if (this.$refs.form.validate() && !this.loading) {
        if (this.form.phone !== this.user.phone && this.user.phone === 11122) {
          await this.$store.dispatch('confirmphone/cancel')
          await this.$store.dispatch('confirmphone/start', {
            phone: this.form.phone,
            slug: this.user.slug,
          })
          this.submitted = true
        } else {
          this.loading = true
          if (this.form.user !== this.form.email) {
            this.loadingDialog = true
            this.loadingText = 'עורך את פרטי המשתמש...'
          }
          const form = { ...this.form }
          if (!form.password || !form.password.length) delete form.password
          const status = await this.$store.dispatch('users/editUser', form)
          if (status) {
            if (this.form.user !== this.form.email) {
              this.waitForNewUser()
            } else {
              this.loading = false
              this.setForm()
            }
          } else {
            this.loading = false
          }
        }
      }
    },
    waitForNewUser () {
      // const newSlug = this.form.email.replaceAll('.', '~')
      const newSlug = this.form.email.split('~').join('.')
      this.loadingText = 'עורך את כתובת האימייל...'
      this.userInterval = setInterval(async () => {
        await this.$store.dispatch('users/updateUser', {
          email: this.form.email,
          slug: this.$route.params.slug,
        })
        const newUser = this.$store.getters['users/getBySlug'](newSlug)
        if (newUser) {
          this.redirectToUser()
        }
      }, 3000)
    },
    redirectToUser () {
      clearInterval(this.userInterval)
      this.loading = false
      // const newSlug = this.form.email.replaceAll('.', '~')
      const newSlug = this.form.email.split('.').join('~')
      if (this.$route.params.slug !== newSlug) {
        this.$router.push({
          name: 'UserDetails',
          params: { slug: newSlug },
        })
      }
    },
    formatTime (time) {
      return moment(time)._isValid ? moment(time).format('lll') : time
    },
  },
}
</script>
